import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cleanOfferShoppingCart } from '@fingo/lib/apollo/helpers/shopping-cart';
import ShoppingCartSummary from '../../ShoppingCartSummary/ShoppingCartSummary';
import OrderingDebtWarning from '../../../ordering/OrderingDebtWarning';
import ShoppingCartSummaryActionButton from '../../ShoppingCartSummary/ShoppingCartSummaryActionButton';
import useOfferShoppingCartSummary from '../../../../hooks/useOfferShoppingCartSummary';

const SummaryStep = ({ nextStep }) => {
  const { t } = useTranslation();
  const {
    summaryItems,
    summaryLoading,
    actionDisabled,
    tooltipTitle,
    orderingDebt,
    hasPurchaseOrdersNearToDebt,
  } = useOfferShoppingCartSummary();

  return (
    <ShoppingCartSummary
      cartType="offer"
      summaryItems={summaryItems}
      cleanFunction={cleanOfferShoppingCart}
      warningAlert={(
        <OrderingDebtWarning
          orderingDebt={orderingDebt}
          hasPurchaseOrdersNearToDebt={hasPurchaseOrdersNearToDebt}
        />
      )}
      loading={summaryLoading}
      actionButton={(
        <ShoppingCartSummaryActionButton
          id="confirm-summary-step-button"
          onClick={nextStep}
          tooltipTitle={tooltipTitle}
          disabled={actionDisabled}
          label={t('offer action')}
        />
      )}
    />
  );
};

SummaryStep.propTypes = {
  nextStep: PropTypes.func.isRequired,
};

export default React.memo(SummaryStep);
