import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import PropTypes from 'prop-types';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import ShoppingCartSummaryActionButton from '../../ShoppingCartSummary/ShoppingCartSummaryActionButton';

const ConfirmationStep = ({ toggleMenu }) => (
  <Stack flexGrow={1} alignItems="center" justifyContent="space-between">
    <Typography variant="h5" gutterBottom paragraph align="center" width="100%">
      Tu factura ha sido enviada a evaluación, te notificaremos cuando tengamos
      una respuesta
    </Typography>
    <TaskAltOutlinedIcon sx={{ color: 'gray.main', fontSize: '170px' }} />
    <ShoppingCartSummaryActionButton
      id="ordering-evaluation-confirmation-button"
      label="OK"
      onClick={toggleMenu}
    />
  </Stack>
);

ConfirmationStep.propTypes = {
  toggleMenu: PropTypes.func,
};

ConfirmationStep.defaultProps = {
  toggleMenu: null,
};

export default ConfirmationStep;
