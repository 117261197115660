import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { checkRut } from 'react-rut-formatter';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { StepTypography } from '@fingo/lib/components/typography';
import { useSnackBars } from '@fingo/lib/hooks';
import EmailField from '@fingo/lib/components/fields/EmailField';
import PasswordField from '@fingo/lib/components/fields/PasswordField';
import RutField from '@fingo/lib/components/fields/RutField';
import LazyLoadImage from '@fingo/lib/components/images/LazyLoadImage';
import useUploadInvoiceProviderCredentials from '../../../../hooks/useUploadInvoiceProviderCredentials';
import ShoppingCartStepperNavigation from '../../ShoppingCartStepperNaviagtion';
import ErrorBox from '../../../boxes/ErrorBox';
import ShoppingCartSummaryActionButton from '../../ShoppingCartSummary/ShoppingCartSummaryActionButton';

// eslint-disable-next-line operator-linebreak
const ERROR_MESSAGE =
  'Uno o más campos son incorrectos. Por favor verifica e intenta nuevamente.';

const VALIDATION_SCHEMA = Yup.object({
  rut: Yup.object({
    raw: Yup.string()
      .required('El RUT es obligatorio')
      .test('isValidRut', 'RUT inválido', (value) => checkRut(value)),
  }),
  email: Yup.string().email('El correo electrónico no es válido'),
  password: Yup.string().required('La contraseña es obligatoria'),
});

const INITIAL_VALUES = {
  rut: { formatted: '', raw: '' },
  email: '',
  password: '',
};

const CredentialProviderCredentialsStep = ({ prevStep, selectedProvider }) => {
  const { addAlert } = useSnackBars();
  const { uploadCredentials, loading } = useUploadInvoiceProviderCredentials();

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        uploadCredentials({
          variables: {
            password: values.password,
            naturalPersonRut: values.rut.raw,
            credentialTypeId: selectedProvider.id,
            secondUserName: values.email,
          },
          onCompleted: () => {
            setSubmitting(false);
            addAlert({
              id: 'credential-provider-credentials-success',
              message: 'Credenciales ingresadas exitósamente!',
              severity: 'success',
              color: 'success',
            });
          },
          onError: () => {
            setFieldError('submit', ERROR_MESSAGE);
            setSubmitting(false);
          },
        });
      }}
    >
      {({ errors, isSubmitting }) => (
        <Form style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <StepTypography stepNumber="3" variant="h6" fontWeight="500" mb={2}>
            <Stack direction="row" display="inline-flex" alignItems="center">
              Ingresa las credenciales de tu facturador
              <Paper elevation={1} sx={{ ml: 1 }}>
                <LazyLoadImage
                  src={selectedProvider?.iconUrl}
                  alt={selectedProvider?.name}
                  width={60}
                  height={24}
                />
              </Paper>
            </Stack>
          </StepTypography>
          <RutField />
          {selectedProvider?.requiresEmail && <EmailField />}
          <PasswordField />
          {errors.submit && <ErrorBox>{errors.submit}</ErrorBox>}
          <ShoppingCartStepperNavigation onBack={prevStep}>
            <ShoppingCartSummaryActionButton
              id="confirm-credential-provider-credentials-button"
              type="submit"
              label="Siguiente"
              loading={loading || isSubmitting}
              disabled={loading || isSubmitting}
            />
          </ShoppingCartStepperNavigation>
        </Form>
      )}
    </Formik>
  );
};

CredentialProviderCredentialsStep.propTypes = {
  prevStep: PropTypes.func.isRequired,
  selectedProvider: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    iconUrl: PropTypes.string,
    requiresEmail: PropTypes.bool,
  }),
};

CredentialProviderCredentialsStep.defaultProps = {
  selectedProvider: null,
};

export default CredentialProviderCredentialsStep;
