import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import PropTypes from 'prop-types';
import { cleanOfferShoppingCart } from '@fingo/lib/apollo/helpers/shopping-cart';
import { shoppingCartOffersVar } from '@fingo/lib/apollo/reactive-variables/shopping-cart';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import ShoppingCartSummaryActionButton from '../../ShoppingCartSummary/ShoppingCartSummaryActionButton';

const ConfirmationStep = ({ toggleMenu }) => (
  <Stack flexGrow={1} alignItems="center" justifyContent="space-between">
    <Typography variant="h5" gutterBottom paragraph align="center" width="100%">
      Te notificaremos cuando se realice el giro.
    </Typography>
    <TaskAltOutlinedIcon sx={{ color: 'gray.main', fontSize: '170px' }} />
    <ShoppingCartSummaryActionButton
      id="operation-confirmation-button"
      label="OK"
      onClick={() => {
        toggleMenu();
        cleanOfferShoppingCart();
        shoppingCartOffersVar([]);
      }}
    />
  </Stack>
);

ConfirmationStep.propTypes = {
  toggleMenu: PropTypes.func,
};

ConfirmationStep.defaultProps = {
  toggleMenu: null,
};

export default ConfirmationStep;
